import { TFunction } from "i18next";
import { DOCUMENTTEMPLATE_NAMES } from "../Constants";

export const getDocumentIndexListConfig = (name: string, t: TFunction) => {
  let config = [] as any[];

  switch (name) {
    case DOCUMENTTEMPLATE_NAMES.BANKNOTE:
    case DOCUMENTTEMPLATE_NAMES.COUNTERFEIT_BANKNOTE:
      config = [
        { title: t("properties:code"), dataIndex: "code" },
        {
          title: t("properties:denomination"),
          dataIndex: "",
          render: (document: any) => document.denomination,
        },
        {
          title: t("properties:inCirculation"),
          dataIndex: "",
          render: (document: any) => {
            return document?.inCirculation?.replace("Attribute", "");
          },
        },
        {
          title: t("properties:series"),
          dataIndex: "",
          render: (document: any) => {
            return document?.seriesYear;
          },
        },
      ];
      break;
    case DOCUMENTTEMPLATE_NAMES.MULTI_SIDED:
    case DOCUMENTTEMPLATE_NAMES.DOUBLE_SIDED:
    default:
      config = [
        { title: t("properties:code"), dataIndex: "code" },
        {
          title: `${t("properties:ages")} / ${t("properties:status")}`,
          dataIndex: "",
          render: (document: any) => document?.ages ?? document?.status,
        },
        {
          title: `${t("properties:documentCategory")} / ${t("properties:inCirculation")}`,
          dataIndex: "",
          render: (document: any) => {
            return document?.documentCategory ?? document?.inCirculation;
          },
        },
        {
          title: `${t("properties:series")} / ${t("properties:dateInCirculation")}`,
          dataIndex: "",
          render: (document: any) => {
            return document?.series ?? document?.dateInCirculation;
          },
        },
      ];
      break;
  }

  return config;
};
